import { useState, useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios';
import Pagination from '../../component/Admin/Pagination';
import { FaMailBulk, FaRegEye, FaTrashAlt } from 'react-icons/fa';
import swal from 'sweetalert';
import { formatDate, formatTime } from '../../helper/helper';
import Loading from '../../component/Loading';
import { NavLink } from 'react-router-dom';
const Daftar = () => {
    const token = JSON.parse(localStorage.getItem("token"));
    const header = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    const [listUsers, setListUsers] = useState([]);
    const [periode, setPeriode] = useState(localStorage.getItem('periode') || ''); // Mengambil periode dari localStorage
    const [status, setStatus] = useState('all');
    const [formulir, setFormulir] = useState('all');
    const [meta, setMeta] = useState({});
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState([]);

    useEffect(() => {
        if (periode) {
            getListUsers();
        }
        // eslint-disable-next-line
    }, [periode, page, status, formulir, limit]);

    const getListUsers = () => {
        // setLoadingSmall(true)
        axios.get(`api/users/aplikan/online/${periode}/${status}/${formulir}?page=${page}&limit=${limit}`, header)
            .then((response) => {
                setMeta(response.data.meta);
                setListUsers(response.data.items);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const onChangePeriode = (event) => {
        setPeriode(event.target.value);
        localStorage.setItem('periode', event.target.value); // Menyimpan periode ke localStorage

        setPage(1);
    };
    const onChangeFormulir = (event) => {
        setFormulir(event.target.value);
        setPage(1);

    };
    const onChangeStatus = (event) => {
        setStatus(event.target.value);
        setPage(1);

    };

    const selectPage = (value) => {
        setPage(value);
    };

    const nextPage = () => {
        if (page < meta.totalPages) {
            setPage(page + 1);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    const limitPage = (event) => {
        setLimit(Number(event.target.value));
    };

    const handleCheckboxChange = (event, email) => {
        if (event.target.checked) {
            setUserEmail([...userEmail, email]);
        } else {
            setUserEmail(userEmail.filter(item => item !== email));
        }
    };

    const checkAll = (event) => {
        if (event.target.checked) {
            const allEmails = listUsers.map(user => user.Email);
            setUserEmail(allEmails);
        } else {
            setUserEmail([]);
        }
    };

    const kirim = async () => {
        swal({
            title: `Kirim ke ${userEmail.length} Orang `,
            text: 'Tuliskan Pesan',
            content: "input",
            buttons: ["Batal", "Kirim"],
        })
            .then(async (value) => {
                if (value) {
                    setLoading(true)
                    for (const email of userEmail) {
                        const user = listUsers.find(user => user.Email === email);
                        const dataCama = {
                            AplikanID: user.AplikanID,
                            Nama: user.Nama,
                            Email: user.Email,
                            Pesan: value
                        }
                        try {
                            await axios.post('api/users/aplikan/sendMail', dataCama, header)
                                .then(res => {
                                    console.log(`email terkirim ke ${dataCama.Email}`);
                                    swal({
                                        title: "Kirim Email",
                                        text: `Dikirim ke ${dataCama.Nama} - (${dataCama.Email}) `,
                                        timer: 1000
                                    });
                                    swal.stopLoading();
                                    swal.close();
                                })
                        } catch (error) {
                            console.error(`Error sending email to ${email}:`, error);
                        }
                    }
                }
                setLoading(false)

            })
            .catch(err => {
                if (err) {
                    swal("Oh noes!", "The AJAX request failed!", "error");
                } else {
                    swal.stopLoading();
                    swal.close();
                }
            });
    }

    return (
        <>
            {loading ? <Loading /> : null}

            <div className="flex py-2 bg-white rounded-lg flex-col">
                <div className='flex my-4 justify-between min-w-full sm:px-6 lg:px-8 items-center'>
                    <div className='text-sm  flex  items-center'>
                        <div className='  pr-2'>PERIODE : </div>
                        <select
                            className="outline-none focus:outline-none p-2 bg-gray-100 border rounded-lg"
                            value={periode}
                            onChange={onChangePeriode}
                        >
                            <option value=''>Pilih Periode</option>
                            <option value='20232'>20232</option>
                            <option value='20241'>20241</option>
                            <option value='20242'>20242</option>
                        </select>
                    </div>
                    <div className='  flex  items-center'>
                        <div className='text-sm pr-2'>FORMULIR : </div>
                        <select
                            className=" text-sm outline-none focus:outline-none p-2 bg-gray-100 border rounded-lg"
                            value={formulir}
                            onChange={onChangeFormulir}
                        >
                            <option value='all'>Lengkap/Belum Lengkap</option>
                            <option value='N'>Lengkap</option>
                            <option value='Y'>Belum Lengkap</option>
                        </select>
                    </div>
                    <div className='text-sm  flex  items-center'>
                        <div className='pr-2'>STATUS CAMA : </div>
                        <select
                            className="outline-none focus:outline-none p-2 bg-gray-100 border rounded-lg"
                            value={status}
                            onChange={onChangeStatus}
                        >
                            <option value='all'>Pilih Status</option>
                            <option value='APL'>APL</option>
                            <option value='BLI'>BLI</option>
                            <option value='REG'>REG</option>
                        </select>
                    </div>
                    <div onClick={userEmail.length > 0 ? kirim : null} className={`${userEmail.length > 0 ? "hover:bg-slate-200 bg-[#f7f7f7]" : " text-gray-400 bg-gray-100 "} border cursor-pointer  flex justify-center px-3 rounded-md items-center  w-auto`}>
                        <div className='  rounded  p-2 text-3xl text-[#ef7e7e]'><FaMailBulk /></div>
                        <span className='' >Kirim Pesan</span>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden rounded-xl">
                            <table className="border min-w-full">

                                <thead className="bg-[#805c5d] border-b">
                                    <tr>
                                        <th scope="col" className="text-xs  font-medium text-white pl-4 py-2 text-center">No.</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">PMBID</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">NAMA</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">EMAIL</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">ALAMAT</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">STATUS</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-center">Last Send</th>
                                        <th scope="col" className="text-xs font-medium text-white px-6 py-4 text-left">ACTION</th>
                                        <th scope="col" className="text-xs font-medium text-white px-4 text-left"><input type="checkbox" onChange={checkAll} /></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listUsers.length > 0 ? (
                                        listUsers.map((user, index) => (
                                            <tr key={index} className={` border-b ${user.StatusAplikanID === "APL" ? "bg-white" : "bg-green-200"}`}>
                                                <td className="   text-xs font-medium text-gray-900">{(page - 1) * limit + index + 1}</td>
                                                <td className="text-xs text-gray-900 font-light px-3 py-3 whitespace-nowrap text-left">{user.AplikanID}</td>
                                                <td className="text-xs text-gray-900 font-light px-3 py-3 whitespace-nowrap text-left">{user.Nama}</td>
                                                <td className="text-xs text-gray-900 font-light px-3 py-3 whitespace-nowrap text-left">{user.Email}</td>
                                                <td className="text-xs text-gray-900 font-light px-3 py-3 whitespace-nowrap text-left">{user.propinsi?.NamaPropinsi}</td>
                                                <td className="text-xs text-gray-900 font-light px-3 py-3 whitespace-nowrap text-left">{user.StatusAplikanID}</td>
                                                <td className=" text-gray-400 font-light px-3 text-sm py-3 whitespace-nowrap text-center"><small><i>{user.lastSend ? formatDate(user.lastSend) + " " + formatTime(user.lastSend) : "Belum Pernah"}</i></small></td>
                                                <td className="text-xs text-gray-900 font-light  whitespace-nowrap text-left">
                                                    <div className=' flex justify-center items-center'>
                                                        <span className=' shadow hover:bg-slate-200 cursor-pointer rounded bg-[#f7f7f7] p-2 text-xl text-[#ef7e7e]'><FaTrashAlt /></span>
                                                        <span className='p-1'>|</span>
                                                        <NavLink className=' shadow hover:bg-slate-200 cursor-pointer rounded bg-[#f7f7f7] p-2 text-xl text-green-500' to={`${user.AplikanID}`}>
                                                            <span ><FaRegEye /></span>
                                                        </NavLink>
                                                    </div>
                                                </td>
                                                <td>
                                                    <input type="checkbox" checked={userEmail.includes(user.Email)} onChange={(event) => handleCheckboxChange(event, user.Email)} />
                                                </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr className="bg-white border-b">

                                            <td colSpan={8} className="px-6 py-4 whitespace-nowrap text-2xl font-medium text-gray-300">
                                                <div className='flex justify-center'>
                                                    <div className=' items-center text-center'>
                                                        Data Periode {periode} Tidak Ditemukan
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            <Pagination
                                currentPage={page}
                                totalPages={meta.totalPages}
                                limitPage={limitPage}
                                selectPage={selectPage}
                                nextPage={nextPage}
                                prevPage={prevPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Daftar;
// Data Periode { periode } Tidak Ditemukan
// Data Periode { periode } Tidak Ditemukan
// Data Periode { periode } Tidak Ditemukan
